import React from 'react';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';

import searchSVG from './img/search.svg';
import './Input.scss';

const Input = ({ value, name, callBack, placeholder = '', size = 'default', type = 'text', isRequired, ...props }) => {
  const b = block('input');
  return (
    <div className={b('wrapper')}>
      {type === 'search' && <SVGInline svg={searchSVG} className={b('icon').toString()} />}
      <input
        type={type}
        name={name}
        value={value}
        placeholder={placeholder || ''}
        onChange={callBack}
        className={b({ sizable: size, type })}
        required={isRequired}
        {...props}
        />
    </div>
  );
};

export default Input;
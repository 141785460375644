import React from 'react';
import block from 'bem-cn';
import PT from 'prop-types';
import Select from "components/Select";

import './BetTypesFilter.scss';

const BetTypesFilter = props => {
	const b = block('bet-types-filter');
	const {locale, onChange, filters} = props
	const betTypes = [
		{name: locale.betTypes['0'], value: ''},
		{name: locale.betTypes['1'], value: '1'},
		{name: locale.betTypes['2'], value: '2'},
		{name: locale.betTypes['3'], value: '3'},
	];

	const handleBetTypesChange = value => {
		const betType = betTypes.find(item => item.value === value)
		onChange({...filters, betType})
	}
	return (
		<section className={b()}>
			<div className={b('filter-row')}>
				<div className={b('text')}>{locale.betType}:</div>
				<Select items={betTypes} activeItem={filters.betType} onChange={handleBetTypesChange}/>
			</div>
		</section>
	)
};

BetTypesFilter.propTypes = {
	locale: PT.object.isRequired,
	onChange: PT.func.isRequired,
	filters: PT.exact({
		betType: PT.exact({name: PT.string, value: PT.string}),
	}).isRequired
};

export default BetTypesFilter
import { errorStatusMessage } from './errorStatusMessage';

export const locale = {
  ...errorStatusMessage,
  changeLang: 'Changer langue',
  changePas: 'Changer pasword',
  logout: 'Deconnecter',
  other: 'Other',

  newPlayer: 'Nouveau client',
  userName: 'Nom de utilisateur',
  password: 'Mot de passe',
  createAccount: 'Creer un compte',
  '/user-create': 'Cash register - New player',
  '/total': 'Cash register - comptabilitee',
  '/': 'Menu',
  '/locale': 'Languagies',
  '/change-pas': 'Changer pasword',
  '/user-edit': 'Cash register - User edit',
  '/cashier-jackpot': 'Cash Registre - Cashier Jackpot',
  '/users-list': 'Cash register - balance des clients',
  userCardBalances: 'Balances des clients',
  userCard: 'Clients',
  accountBalance: 'Balance de compte',
  nickname: 'Nom de utilisateur',
  id: 'Identifiant',
  balance: 'Balance',
  ban: 'Bloquer',
  banSuccess: 'Bloquer success',
  unbanSuccess: 'Debloquer success',
  unban: 'Debloquer',
  resetPassword: 'Réinitialiser',
  resetSuccess: 'Mot de passe réinitialisee',
  networkError: 'Erreur de reseau',
  createSuccess: 'Succès de creation',
  topUp: 'Depot',
  betsMenu: 'Pari',
  page: 'Page',
  withdrawal: 'Retrait',
  topUpSuccess: 'Succès  de depot',
  withdrawalSuccess: 'Succès  de retrait',
  topUpWithdrawal: 'Depot / Retrait',
  ok: 'Ok',
  theNewPassword: 'Nouveau mot de passe ',
  for: 'pour',

  betTypes: {
    '0': 'Tous les types',
    '1': 'Gagner',
    '2': 'Pari',
    '3': 'Remboursement',
  },
  userId: 'Id utilisateur:',
  betType: 'Type de pari',
  selectPeriod: 'Sélectionnez la période',
  from: 'From',
  to: 'To',
  apply: 'Appliquer',
  betTableHeads: {
    id: 'ID',
    type: 'Taper',
    createdAt: 'Créé à',
    userId: 'Utilisateur ID',
    provider: 'Fournisseur',
    gameType: 'Type de jeu',
    value: 'Évaluer',
    betType: 'Type de pari',
  },

  '/transaction-history': 'clients - Historique de transactions',
  startDate: 'Date de debut',
  endingDate: 'Date de fin',
  display: 'Representer',
  email: 'E-mail',
  date: 'Date',
  noBets: 'Pas de paris',
  amount: 'montant',

  languageSelection: 'selectionner langues',
  login: 'Me Connecter',

  cashRegister: 'Cash register',
  partners: 'clients',
  createUser: {
    1: 'New user',
    2: 'New cashier',
    3: 'New admin',
    6: 'New admin',
  },
  balances: {
    1: 'User cards balances',
    2: 'Shop cards balances',
    3: 'Admins cards balances',
    6: 'Admins cards balances',
  },
  cashiersTransactions: {
    1: 'Cashier transactions',
    2: 'Shop transactions',
    3: 'Admins transactions',
    6: 'Admins transactions',
  },
  bets: {
    1: 'Pari',
    2: 'Pari',
    3: 'Pari',
    6: 'Pari',
  },
  transactionHistory: {
    1: 'Transaction history',
    2: 'Transaction history',
    3: 'Transaction history',
    6: 'Transaction history',
  },
  revenuBalances: {
    1: 'Revenu balances',
    2: 'Revenu balances',
    3: 'Revenu balances',
    6: 'Revenu balances',
  },
  cashierJackpot: {
    1: 'Cashier jackpot',
    2: 'Cashier jackpot',
    3: 'Cashier jackpot',
    6: 'Cashier jackpot',
  },

  total: 'Totale',
  term: 'Terme',
  cashPayment: 'Payement en espece',
  cashPayout: 'Deboursement en espece',
  withdraw: 'Retrait',
  deposit: 'Depot',
  search: 'Search',
  errorPassword:
    'Le mot de passe doit contenir au moins 6 chiffres, 2 lettres',
  noPass: 'Le mot de passe ne doit pas être vide!',
  dataUpdate:
    'Les données sont mises à jour toutes les 12 heures. Par conséquent, les données du jour en cours peuvent être incorrectes',
  treeMenu: 'Tree',
  tree: {
    1: 'Tree',
    2: 'Tree',
    3: 'Tree',
    6: 'Tree',
  },
  players: 'Joueurs',
  subusers: 'Sous-utilisateurs',

  jackpotWinnersList: 'Liste des gagnants de Jackpot',
  startAmount: 'Montant de départ',

  winnerID: 'ID gagnant',
  winDate: 'Remporter la date',
  amountOfTheWinningJackpot: 'Montant du jackpot gagnant',
  all: 'Tout',
  gameType: 'Type de jeu',
  provider: 'Fournisseur',
};

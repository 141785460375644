import React from 'react';
import block from 'bem-cn';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import './TransactionItem.scss';

dayjs.extend(utc);

const TransactionItem = ({ item }) => {
  const b = block('transaction-item');

  return (
    <div className={b()}>
      <span className={b('column', { type: 'left' })}>{item.email}</span>
      <span className={b('column', { type: 'center' })}>
        {dayjs(+dayjs(item.date.toString()).add(1, 'hours')).format(
          'YYYY.MM.DD HH:mm'
        )}
      </span>
      <span
        className={b(
          'column-amount',
          { type: 'right' },
          { colored: item.amount > 0 }
        )}
      >
        {item.amount}
      </span>
      <span className={b('column', { type: 'right' })}>{item.balance}</span>
    </div>
  );
};

export default TransactionItem;

import React, { useState } from 'react';
import block from 'bem-cn';
import PropTypes from 'prop-types';
import SVGInline from 'react-svg-inline';

import ArrowSVG from './img/arrow.svg';

import './Paginator.scss';

const Paginator = ({ pages, currentPage, locale, changePage }) => {
  const b = block('slots-paginator');
  const [isOpen, changeOpen] = useState(false);
  return (
    <div className={b({ open: isOpen })} onClick={() => changeOpen(!isOpen)}>
      <span className={b('title')}>{`${locale.page}:`}</span>
      <div className={b('pages')}>
        <div className={b('current-page')}>
          {currentPage}
          <SVGInline className={b('arrow').toString()} svg={ArrowSVG}/>
        </div>
        {isOpen ? <ul className={b('pages-list')}>
          {new Array(pages).fill(null).map((el, i) => {
            const page = i + 1,
              isActive = page === currentPage;
            return (
              <li
                key={i} className={b('page', {active: isActive})}
                onClick={isActive ? null : () => changePage(page)}
              >
                {page}
              </li>
            )
          })}
        </ul> : null}
      </div>
    </div>
  );
};

Paginator.propTypes = {
  pages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  locale: PropTypes.object.isRequired,
  changePage: PropTypes.func.isRequired,
};

export default Paginator
import dayjs from 'dayjs';

const getLocalTime = value => {
  const localTime = value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss') : '';
  return localTime;
};

export class BetsApiConverter {
  convertTransferList = ({ data, totalCount }) => {
    return {
      count: totalCount,
      transfers: data.map(t => ({
        id: t?.id,
        type: t?.type,
        createdAt: getLocalTime(t?.date),
        userId: t?.userId,
        provider: t?.provider,
        gameType: t?.gameType,
        value: t?.amount,
        betType: t?.status,
      })),
    };
  };
}

import React, { useCallback, useEffect, useState } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import dayjs from 'dayjs';

import { actions } from '../redux';
import useProviderFilters from 'hooks/useProviderFilters';

import Paginator from 'components/Paginator';
import Select from 'components/Select';

import { providersItems, gameTypeItems } from '../data';
import BetsFilter from './BetsFilter';
import BetTypesFilter from './BetTypesFilter';
import Table from './Table';

import './Bets.scss';
import Input from '../../../components/Input';

const Bets = () => {
  const b = block('bets');
  const dispatch = useDispatch();
  const locale = useSelector(state => state.locale.locale);
  const { transfers, count } = useSelector(state => state.bets, shallowEqual);
  const allProviders = [{ value: '', name: locale.all }, ...providersItems];
  const allTypes = [{ value: '', name: locale.all }, ...gameTypeItems];

  const [userId, changeUserId] = useState('');
  const [provider, changeProvider] = useState(allProviders[0]);
  const [gameType, changeGameType] = useState(allTypes[0]);
  const [page, changePage] = useState(1);
  const [date, setDate] = useState({
    beginDate: new Date(dayjs().add(-1, 'month')),
    endDate: new Date(dayjs().add(1, 'day')),
  });
  const pagination = {
    offset: page - 1,
    limit: 10,
    pages() {
      return Math.ceil(count / this.limit);
    },
  };

  const { filters, setFilters } = useProviderFilters(locale);
  const betTableHeads = { ...locale.betTableHeads };

  const getTransfers = useCallback(() => {
    const { beginDate, endDate } = date;
    const format = 'YYYY-MM-DD';
    const type = filters.betType.value === '0' ? '' : filters.betType.value;
    const info = {
      userId: userId,
      dateFrom: dayjs(beginDate).format(format),
      dateTo: dayjs(endDate).format(format),
      gameType: gameType.value,
      provider: provider.value,
      status: type,
      page: pagination.offset,
      count: pagination.limit,
    };
    const queryParams = [...Object.entries(info)]
      .map(el => el.join('='))
      .join('&');
    dispatch(actions.getTransfers(queryParams));
  }, [date, page, filters, userId, provider, gameType]);

  const onChangeBeginDate = value => {
    setDate({ ...date, beginDate: value });
  };
  const onChangeEndDate = value => {
    setDate({ ...date, endDate: value });
  };
  const onChangeUserId = e => {
    changeUserId(e.currentTarget.value);
  };
  const handleTypeChange = value => {
    changeGameType(allTypes.find(item => item.value === value) ?? allTypes[0]);
  };
  const handleProviderChange = value => {
    changeProvider(
      allProviders.find(item => item.value === value) ?? allTypes[0]
    );
  };

  useEffect(() => {
    if (userId) getTransfers();
  }, [page]);

  return (
    <div className={b()}>
      <div className={b('user-id-input')}>
        <p>{locale.userId}</p>
        <Input value={userId} callBack={onChangeUserId} type={'number'} />
      </div>
      <BetTypesFilter filters={filters} locale={locale} onChange={setFilters} />
      <div className={b('filter-row')}>
        <div className={b('text')}>{locale.gameType}:</div>
        <Select
          items={allTypes}
          activeItem={gameType}
          onChange={handleTypeChange}
        />
      </div>
      <div className={b('filter-row')}>
        <div className={b('text')}>{locale.provider}:</div>
        <Select
          items={allProviders}
          activeItem={provider}
          onChange={handleProviderChange}
        />
      </div>
      <BetsFilter
        locale={locale}
        beginDate={date.beginDate}
        endDate={date.endDate}
        onSubmit={getTransfers}
        onChangeBeginDate={onChangeBeginDate}
        onChangeEndDate={onChangeEndDate}
      />
      <div className={b('table')}>
        <Table locale={locale} heads={betTableHeads} items={transfers} />
      </div>
      <div className={b('pagination')}>
        <Paginator
          pages={pagination.pages()}
          currentPage={page}
          locale={locale}
          changePage={changePage}
        />
      </div>
    </div>
  );
};

export default Bets;

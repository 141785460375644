export default [
  {
    textId: 'cashRegister',
    items: [
      {
        textId: 'createUser',
        route: '/user-create',
      },
      {
        textId: 'balances',
        route: '/users-list',
      },
      {
        textId: 'cashiersTransactions',
        route: '/total',
      },
      {
        textId: 'transactionHistory',
        route: '/transaction-history',
      },
      {
        textId: 'tree',
        route: '/tree',
      },
      {
        textId: 'cashierJackpot',
        route: '/cashier-jackpot',
        allowedRoles: [1],
      },
      {
        textId: 'bets',
        route: '/bets',
      },
    ],
  },
];

import React from 'react';
import block from 'bem-cn';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import './Table.scss';

const Table = ({ locale, heads, items }) => {
  const b = block('table');
  const betTypeLocale = locale.betTypes;

  return (
    <div className={b()}>
      {items.length ? (
        items.map(item => {
          const headKeys = Object.keys(heads).filter(headKey => item[headKey]);
          return (
            <div className={b('row')} key={item.id}>
              {headKeys.map(headKey => (
                <p className={b('row-p')} key={headKey}>
                  <span className={b('row-span')}>{heads[headKey]}:</span>
                  <br />
                  {headKey === 'createdAt'
                    ? betTypeLocale[item[headKey]] ??
                      dayjs(+dayjs(item[headKey]).add(1, 'hours')).format(
                        'YYYY-MM-DD HH:mm:ss'
                      )
                    : betTypeLocale[item[headKey]] ?? item[headKey]}
                </p>
              ))}
            </div>
          );
        })
      ) : (
        <h3 className={b('no-bets')}>{locale.noBets}</h3>
      )}
    </div>
  );
};

Table.propTypes = {
  locale: PropTypes.object.isRequired,
  heads: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
};

export default Table;

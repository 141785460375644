import React, { useEffect, useState, useMemo, useCallback } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import SVGInline from 'react-svg-inline';
import { Link } from 'react-router-dom';

import { actions } from 'features/users/redux';

import Input from 'components/Input';

import arrowSvg from '../img/arrow.svg';

import './UsersList.scss';

const UsersList = () => {
  const b = block('users-list');
  const [filterValue, onChangeFilterValue] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getUsersList());
  }, [dispatch]);

  const changeFilterValue = useCallback(e => {
    onChangeFilterValue(e.currentTarget.value);
  }, []);

  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const usersList = useSelector(state => state.users.usersList, shallowEqual);

  const list = useMemo(() => usersList.users?.filter(t =>
    ~t.nickname?.toUpperCase().indexOf(filterValue.toUpperCase()) || false).map(item => (
      <Link key={item.id} className={b('item')} to={`/user-edit/${item.id}`}>
        <div className={b('item-nick')}>{`${item.nickname} (ID: ${item.id})`}</div>
        <div className={b('item-right')}>
          <div className={b('item-balance')}>{item.balance}</div>
          <div className={b('arrow-box')}><SVGInline svg={arrowSvg} className={b('arrow').toString()} /></div>
        </div>
      </Link>
    )) || [], [b, filterValue, usersList])

  return (
    <div className={b()}>
      <h1 className={b('title')}>{locale.userCardBalances}</h1>
      <div className={b('header')}>
        <div className={b('header-text')}>{locale.userCardBalances}</div>
        <Input
          type="search"
          value={filterValue}
          callBack={changeFilterValue}
          placeholder={`${locale.search}...`}
        />
      </div>
      <div className={b('subheader')}>
        <div className={b('user-card')}>
          <div className={b('item-nick')}>{locale.userCard}</div>
          <div className={b('item-right')}>
            <span className={b('bold-text')}>{usersList.quantity}</span>
          </div>
        </div>
        <div className={b('account-balance')}>
          <div className={b('item-nick')}>{locale.accountBalance}</div>
          <div className={b('item-right')}>
            <span className={b('bold-text')}>{usersList.totalBalance}</span>
          </div>
        </div>
      </div>
      <div className={b('list')}>
        {list}
      </div>
    </div>
  );
};

export default UsersList;
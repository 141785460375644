const providersItems = [
  {
    value: 'OUTCOMEBET',
    name: 'OUTCOMEBET',
  },
  {
    value: 'BETCONSTRUCT',
    name: 'BETCONSTRUCT',
  },
  {
    value: 'SLOTEGRATOR',
    name: 'SLOTEGRATOR',
  },
  {
    value: 'BSW',
    name: 'BSW',
  },
  {
    value: 'EVOPLAY',
    name: 'EVOPLAY',
  },
  {
    value: 'PRAGMATIC',
    name: 'PRAGMATIC',
  },
  {
    value: 'FIABLE',
    name: 'FIABLE',
  },
  {
    value: 'HABANERO',
    name: 'HABANERO',
  },
  {
    value: 'SOFTGAMING',
    name: 'SOFTGAMING',
  },
  {
    value: 'EZUGI',
    name: 'EZUGI',
  },
  {
    value: 'EVOLUTION',
    name: 'EVOLUTION',
  },
];

const gameTypeItems = [
  {
    value: 'slots',
    name: 'slots',
  },
  {
    value: 'live-casino',
    name: 'live-casino',
  },
  {
    value: 'sports',
    name: 'sports',
  },
];

export { providersItems, gameTypeItems };

import React, { useState, useCallback } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch } from 'react-redux'
import SVGInline from 'react-svg-inline';

import Input from 'components/Input';
import { actions } from '../redux';
// import lock from './img/lock.svg';
import login from './img/login.svg';
import logoIMG from './img/logo.webp';

import './Auth.scss';

const Auth = () => {
  const b = block('auth');
  const [userName, changeUserName] = useState('');
  const [password, passwordUserName] = useState('');
  const locale = useSelector(state => state.locale.locale);
  const { isProcess } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const signIn = useCallback(() => {
    if (!isProcess) {
      dispatch(actions.login(userName, password));
    }
  }, [userName, password, isProcess, dispatch]);
  return <section className={b()}>
    <div className={b('title')}>
      <SVGInline svg={login} className={b('login-img').toString()} />
      {locale.login}
    </div>
    <img className={b('logo')} src={logoIMG} alt="" />
    <div className={b('input-block')}>
      <div className={b('title-text')}>{`${locale.userName}:`}</div>
      <Input value={userName} onChange={e => changeUserName(e.currentTarget.value)} />
    </div>
    <div className={b('input-block')}>
    <div className={b('title-text')}>{`${locale.password}:`}</div>
      <Input value={password} onChange={e => passwordUserName(e.currentTarget.value)} type="password" />
    </div>
    <div className={b('bottom')}>
      <div className={b('button', { disabled: isProcess })} onClick={signIn}>
        {locale.login}
      </div>
    </div>
  </section>
}

export default Auth;

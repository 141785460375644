import React, { useState } from 'react';
import block from 'bem-cn';
import SVGInlive from 'react-svg-inline';
import ArrowSVG from './img/arrow.svg';

import './Select.scss';

const Select = ({ items, activeItem, onChange, color, size }) => {
  const b = block('select');
  const [isOpen, changeOpen] = useState(false);
  const itemsList = items.map(item => (
    <li key={item.value} className={b('item', { size })} onClick={() => onChange(item.value)}>
      {item.name}
    </li>
  ));
  return (
    <div className={b({ open: isOpen, size, color })} onClick={() => changeOpen(!isOpen)} onMouseLeave={() => changeOpen(false)}>
      <div className={b('item', { current: true })}>
        {activeItem.name}
        <SVGInlive className={b('arrow', { color }).toString()} svg={ArrowSVG} />
      </div>
      {isOpen && <ul className={b('items-list', { color })}>{itemsList}</ul>}
    </div>
  );
};

export default Select;
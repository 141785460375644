import React, { useState, useCallback, useEffect, useMemo } from "react";
import block from "bem-cn";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import SVGInline from "react-svg-inline";
import dayjs from "dayjs";

import { actions } from "../redux";
// import Spinner from "components/Spinner";
import TreeRow from "./TreeRow/TreeRow";

import "./Tree.scss";

const Tree = () => {
  const b = block("tree");
  const dispatch = useDispatch();
  // const locale = useSelector((state) => state.locale.locale);
  // const userId = useSelector((state) => state.auth.id, shallowEqual);
  // const actionProcessing = useSelector(
  //   (state) => state.tree.actionProcessing,
  //   shallowEqual
  // );

  // const usersActionProcessing = useSelector(
  //   (state) => state.users.actionProcessing,
  //   shallowEqual
  // );

  const usersDataList = useSelector((state) => state.tree.users, shallowEqual);

  const rows = useMemo(
    () =>
      usersDataList.map((row) => (
        <TreeRow key={row.userId} nesting={[row.userId]} row={row} />
      )),
    [usersDataList]
  );

  useEffect(() => {
    dispatch(actions.getUsersByUserId());
    return () => dispatch(actions.getUsersByUserId());
  }, []);

  return (
    <div className={b()}>
      {/* <Spinner isLoading={actionProcessing || usersActionProcessing} /> */}
      {rows}
    </div>
  );
};

export default Tree;
